.spinnerOuter {
  z-index: 1000;
}

.spinnerOverlay {
  opacity: 0.6;
}

.spinnerInner {
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}
