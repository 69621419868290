@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'node_modules/bootstrap/scss/mixins';

/////////////////////////////////////////////////////////////////////////////////////////
@each $color, $value in $theme-colors {
  div.card.border-#{$color} .remirror-theme .remirror-toolbar button,
  div.card div.card.border-#{$color} .remirror-theme .remirror-toolbar button,
  div.card div.card div.card.border-#{$color} .remirror-theme .remirror-toolbar button,
  div.card div.card div.card div.card.border-#{$color} .remirror-theme .remirror-toolbar button {
    color: theme-color-level($color, $alert-color-level);
    border: 0px;
  }
  div.card.border-#{$color} .remirror-theme .remirror-toolbar button:hover,
  div.card div.card.border-#{$color} .remirror-theme .remirror-toolbar button:hover,
  div.card div.card div.card.border-#{$color} .remirror-theme .remirror-toolbar button:hover,
  div.card div.card div.card div.card.border-#{$color} .remirror-theme .remirror-toolbar button:hover {
    border: 1px solid rgba(0, 0, 0, 0.12) !important;
    margin: -1px;
  }

  div.card.border-#{$color} .remirror-theme .remirror-toolbar button.Mui-selected,
  div.card div.card.border-#{$color} .remirror-theme .remirror-toolbar button.Mui-selected,
  div.card div.card div.card.border-#{$color} .remirror-theme .remirror-toolbar button.Mui-selected,
  div.card div.card div.card div.card.border-#{$color} .remirror-theme .remirror-toolbar button.Mui-selected {
    border-color: theme-color-level($color, $alert-border-level) !important;
    background-color: theme-color-level($color, $alert-bg-level) !important;
  }

  div.card.border-#{$color} .remirror-theme .remirror-toolbar button.Mui-disabled,
  div.card div.card.border-#{$color} .remirror-theme .remirror-toolbar button.Mui-disabled,
  div.card div.card div.card.border-#{$color} .remirror-theme .remirror-toolbar button.Mui-disabled,
  div.card div.card div.card div.card.border-#{$color} .remirror-theme .remirror-toolbar button.Mui-disabled {
    color: gray !important;
    background-color: rgba(0,0,0,0.08) !important;
  }
}

mark { color: unset; }

.remirror-theme {
  --rmr-font-family-default: "Helvetica", "Arial", sans-serif !important;
  position: relative;
  &:focus-within {
    .remirror-toolbar.bubble:not(.menu-active),
    .remirror-toolbar.bubble.menu-active { visibility: initial; }
  }
  .remirror-toolbar {
    height: 30px;
    border: 1px solid var(--rmr-color-border);
    border-top-left-radius: var(--rmr-radius-border);
    border-top-right-radius: var(--rmr-radius-border);
    padding: 2px;
    > button, > div.MuiBox-root > button, > div.MuiBox-root > .remirror-role > button {
      padding: 3px; 
      width: 24px;
      height: 24px;
    }
    > div.MuiBox-root > .remirror-role:not(:first-of-type) > button {
      border-bottom-left-radius: 0px;
      border-top-left-radius: 0px;
    }
    > div.MuiBox-root > .remirror-role:not(:last-child) > button {
      border-bottom-right-radius: 0px;
      border-top-right-radius: 0px;
    }
    &.bubble {
      position: absolute;
      transform: translateY(-100%);
      flex-wrap: wrap;
      justify-content: center;
      overflow-y: unset;
      height: unset;
      &:not(.menu-active) {
        visibility: hidden;
      }
    }
  }
  .remirror-menu-item {
    width: 100%;
  }
  button.remirror-menu-item-column {
    padding-right: var(--rmr-space-2);
  }
  .remirror-tooltip, &.remirror-tooltip { 
    background-color: white; 
    border: 1px solid black;
    border-radius: 10px;
    padding: 2px;
  }
  div.remirror-editor-wrapper { // need the div to increase the rule specificity
    border: 1px solid var(--rmr-color-border);
    border-radius: var(--rmr-radius-border);
    padding-top: 0px;
    background-color: white;
    .ProseMirror, .ProseMirror:focus, .ProseMirror:active {
      box-shadow: none; 
      height: 100%;
      min-height: unset;
      padding: calc(0.75*var(--rmr-space-3)) var(--rmr-space-3);
      color: initial;
      text-align: left;
    }
  }
  .remirror-toolbar:not(.bubble) + .remirror-editor-wrapper {
    padding-top: 0px;
    border: 1px solid var(--rmr-color-border);
    border-top: 0px;
    border-bottom-left-radius: var(--rmr-radius-border);
    border-bottom-right-radius: var(--rmr-radius-border);
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
  }
  .remirror-floating-popover {
    z-index: 1000;
    > .remirror-toolbar {
      font-size: initial;
      background-color: rgb(68, 68, 68);
      border-radius: 25px; //var(--rmr-radius-border);
      padding: var(--rmr-space-2) var(--rmr-space-3);
      height: calc(24px + 2*var(--rmr-space-2));
      .remirror-separator { border-color: rgb(136, 136, 136); }
      > .remirror-role, > .remirror-group > .remirror-role { background-color: unset; }
      > button, > .remirror-group > button, > .remirror-group > .remirror-role > button { 
        background-color: rgb(68, 68, 68);
        border-color: white;
        color: white;
        width: 28px;
      }
    }
  }
}