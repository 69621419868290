#allAlerts {
  position: fixed;
  margin-top: 70px;
  right: 15px;
  z-index: 10000;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap-reverse;
  max-width: 50%;
}

.toast-body {
  overflow: auto;
  max-height: 30vh;
}

.toast-body, .toast-header {
  right: 15px;
  direction: ltr;
}

.toast {
  flex-basis: initial !important;
  width: 350px !important;
  max-width: 100% !important;
  right: 0;
  resize: horizontal;
  direction: rtl;
}
