@import 'codemirror/lib/codemirror.css';
@import 'codemirror/theme/mdn-like.css';

.CodeMirror {
  border: 1px solid #eee;
  max-width: 100%;
  resize: vertical;
}

.react-codemirror2.h-auto .CodeMirror {
  height: auto !important;
  resize: none;
}

.CodeMirror-scroll {
  max-width: 100%;
  // overflow: auto;
}

.CodeMirror-vscrollbar { visibility: hidden !important; }

// /* Hide scrollbar for Chrome, Safari and Opera */
// .CodeMirror-scroll::-webkit-scrollbar {
//   display: none;
// }

// /* Hide scrollbar for IE, Edge and Firefox */
// .CodeMirror-scroll {
//   -ms-overflow-style: none;  /* IE and Edge */
//   scrollbar-width: none;  /* Firefox */
// } 

.CodeMirror .readOnly {
  background: #8d6d001a;
}
