.sidebar-small {
  width: var(--sidebar-small);
}

.sidebar-expanded {
  width: var(--sidebar-expanded);
}

.collapse:not(.width):not(.show) {
  display: none;
}

.collapsing:not(.width) {
  position: relative;
  height: 0;
  /* overflow: hidden; */
  transition: height 0.35s ease-in-out; 
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  } 
}

.collapse.width, .collapsing.width {
  white-space: nowrap;
  /* overflow: hidden; */
  display: inline-block;
  height: unset; /* undoes bootstrap .collapsing height */
  transition: 0.35s width ease-in-out;
}

.collapse.width:not(.show) {
  display: none;
  height: unset;
}
.collapsing.width {
  position: relative;
  height: unset; /* undoes bootstrap .collapsing height */
  width: 0;
}

.collapse.width.show {
  width: calc(var(--sidebar-expanded) - 7em);
}


.blue-glow {
  /* a somewhat-transparent lightblue */
  background: radial-gradient(closest-side, #add8e688 40%,transparent);
}