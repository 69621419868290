#exam-taker {
  --sidebar-small: calc(6em + 2px);
  --sidebar-expanded: 500px;
}

#exam-taker .sidebar-small + .flex-fill {
  width: calc(100% - var(--sidebar-small));
}

#exam-taker .sidebar-expanded + .flex-fill {
  width: calc(100% - var(--sidebar-expanded));
}
