.scroll-shadowing {
  position: relative;
  overflow: hidden;
  > div {
    overflow: auto;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  > div::before {
    opacity: 0;
    transition: opacity 0.1s linear;
    pointer-events: none;
    position: sticky;
    content: "";
    display: block;
    top: 0;
    margin-top: 0px;
    margin-bottom: -40px;
    padding: 0;
    z-index: 10000;
    width: 100%;
    height: 40px;
    background:
      radial-gradient(
        farthest-side at 50% 0,
        rgba(0, 0, 0, 0.4),
        rgba(0, 0, 0, 0)
      ) center top;
  
    background-repeat: no-repeat;
    background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
    background-attachment: local, local, scroll, scroll;
  }
  > div.not-top::before {
    opacity: 1;
  }

  > div::after {
    opacity: 0;
    transition: opacity 0.1s linear;
    pointer-events: none;
    position: sticky;
    content: "";
    display: block;
    z-index: 10000;
    bottom: 0;
    margin-top: -40px;
    margin-bottom: 0px;
    padding: 0;
    width: 100%;
    height: 40px;
    background:
      /* Shadow TOP */
      radial-gradient(
        farthest-side at 50% 100%,
        rgba(0, 0, 0, 0.4),
        rgba(0, 0, 0, 0)
      ) center bottom;
  }
  > div.not-bot::after {
    opacity: 1;
  }
}
