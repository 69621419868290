@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";

.text-instructions + .rubric { display: none; }

.rubric {
  margin-top: 15px;
  &:first-child { margin-top: 0px; }
  margin-bottom: 15px;
  &:last-child { margin-bottom: 0px; }
  &:not(.alert) { background-color: gray; }
  border-radius: 0.25rem;
  .card {
    padding: 1em;
    > .card-header {
      border: 0px solid transparent;
      padding: 0em;
      background-color: transparent;
      .btn.status-invalid, .btn.status-valid {
        align-self: flex-start;
        color: black;
        cursor: help;
        display: none; 
      }
    }
    > .collapse > .card-body,
    > .collapsing > .card-body {
      padding: 0em;
      background-color: transparent;
    }
    &.status-valid {
      box-shadow: inset 0px 0px 2px 2px theme-color-level('success', 2);
      > .card-header > h5 {
        > .btn.status-valid {
          display: initial;
          background-color: theme-color-level('success', -5);
          box-shadow: inset 0px 0px 2px 2px theme-color-level('success', 2);
        }
      }
    }
    &.status-invalid {
      box-shadow: inset 0px 0px 4px 4px theme-color-level('danger', 2);
      > .card-header > h5 {
        > .btn.status-invalid {
          display: initial;
          background-color: theme-color-level('danger', -5);
          box-shadow: inset 0px 0px 2px 2px theme-color-level('danger', 2);
        }
      }
    }
  }

  .accordion > .card > .card-header {
    cursor: pointer;
  }
  > .card { 
    background-color: transparent; 
    &.card-dark {
      border-color: gray;
    }
    &:not(:last-of-type) {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      border-bottom: 0px;
    }
    &:not(:first-of-type) {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

  }
  .rubric {
    background-color: rgba(255, 255, 255, 0.65); 
    > .card { 
      background-color: transparent;
      margin-bottom: 0;
    }
  }
  
  .card > .form-group.row:last-of-type, .preset:last-of-type {
    margin-bottom: 0;
  }
  .ql-tooltip {
    z-index: 1010;
  }

  p:last-child { margin-bottom: 0; }
}