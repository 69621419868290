@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";

@each $color, $value in $theme-colors {
  .tooltip.bg-#{$color} {
    background-color: unset !important;
    opacity: 1;
    &.bs-tooltip-right > .arrow::before {
      border-right-color: $value !important;
    }
    &.bs-tooltip-left > .arrow::before {
      border-left-color: $value !important;
    }
    &.bs-tooltip-top > .arrow::before {
      border-top-color: $value !important;
    }
    &.bs-tooltip-bottom > .arrow::before {
      border-bottom-color: $value !important;
    }
    .tooltip-inner {
      background-color: $value !important;
    }
  }
}

.tooltip.text-dark .tooltip-inner {
  color: $yiq-text-dark !important;
}

.answer p:last-child { margin-bottom: 0; }

.point-count {
  font-size: 1.5rem;
  color: $text-muted;
}

.z-1000 {
  position: relative;
  z-index: 1000;
}

.z-1000-select {
  &:focus {
    position: relative;
    z-index: 1000;
  }
  .select__menu {
    z-index: 1001;
  }
  .select__menu-list {
    position: relative;
    z-index: 1001; 
  }
}

.size-0 {
  width: 0;
  height: 0;
}

.overhang-left-100 {
  left: -100%;
}

.flex-grow-01 {
  flex-grow: 0.1 !important;
}

.overflow-scroll-x {
  overflow-x: scroll;
}

.overflow-scroll-y {
  overflow-y: scroll;
}

.t-0 {
  top: 0;
}

.b-0 {
  bottom: 0;
}

.r-0 {
  right: 0;
}

.l-0 {
  left: 0;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-move {
  cursor: move;
}

.cursor-grab {
  cursor: grab !important;
}

.transition {
  transition: 0.35s width ease-in-out;
}

.width-0 {
  width: 0;
}

.spoiler {
  cursor: pointer;
  transition: 0.35s background-color ease-in-out;
  > span {
    transition: 0.35s opacity-color ease-in-out;
  }
  &.show {
    background-color: rgba(theme-color("dark"), .2);
    > span {
      opacity: 1;
    }
  }
  &.hide {
    background-color: theme-color("dark");
    > span {
      opacity: 0;
    }
  }
}


.cursor-help {
  cursor: help;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.pointer-events-none {
  pointer-events: none;
}

.pointer-events-auto {
  pointer-events: auto !important;
}

.wrap-anywhere {
  word-wrap: anywhere;
  white-space: break-spaces;
}

.column-count-4 {
  column-count: 4;
}

.bg-pulse {
  background-color: unset;
  animation-name: bg-pulse;
  animation-duration: 0.5s;
  animation-iteration-count: 2;
}

@keyframes bg-pulse {
  0% {
    background-color: unset;
  }
  50% {
    background-color: rgba(theme-color("info"), .2);
  }
  100% {
    background-color: unset;
  }
}

.overflow-visible {
  overflow: visible !important;
}

@each $color, $value in $theme-colors {
  .blue-glow.glow-pulse-#{$color} {
    background: radial-gradient(closest-side, $value 60%,transparent);
    animation-name: none;
  }

  .glow-pulse-#{$color} {
    box-shadow: none;
    position: relative;
    z-index: 1001;
    animation-name: glow-pulse-#{$color};
    animation-duration: 1.5s;
    animation-iteration-count: 3;
  }

  @keyframes glow-pulse-#{$color} {
    0% {
      box-shadow: none;
    }
    50% {
      box-shadow: 0px 0px 40px 10px $value;
    }
    100% {
      box-shadow: none;
    }
  }
}

.profile-photo {
  max-width: 300px;
  max-height: 300px;
}

.profile-photo-thumb {
  max-height: 1em;
}

.tooltip-inner {
    max-width: none !important;
}

.tooltip.show {
  opacity: 1.0 !important; // default was 0.9
}
