@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";

.z-1000-grading {
  z-index: 1001;
}

.question-carousel {
  .carousel-indicators {
    text-align: center;
    position: fixed;
    bottom: 0;
    border-radius: 1em;
    margin-bottom: 0;
    background-color: theme-color("dark");
    margin-left: 35%;
    margin-right: 35%;
  }

  .carousel-control-prev, .carousel-control-next {
    color: theme-color("dark") !important;
    opacity: initial;
    width: auto;
    position: fixed;
    padding: 7em 0.5em;
    z-index: 1000;
    top: calc(50% - 7em);
    bottom: calc(50% - 7em);

    &:hover {
      color: black !important;
    }
  }

  .carousel-control-prev {
    border-top-right-radius: 2em;
    border-bottom-right-radius: 2em;
    &:hover {
      padding-right: 2em;
      background: linear-gradient(to left, transparent, theme-color("secondary"));
    }
  }

  .carousel-control-next{
    border-top-left-radius: 2em;
    border-bottom-left-radius: 2em;
    &:hover {
      padding-left: 2em;
      background: linear-gradient(to right, transparent, theme-color("secondary"));
    }
  }
}

.grouped-dropdown-holder {
  margin-left: -$dropdown-border-width;
  margin-right: -$dropdown-border-width;
  margin-bottom: -$dropdown-border-width;
  @include border-radius(4*$dropdown-border-radius);
}

.bordered-menu-group {
  border: $dropdown-border-width solid $dropdown-border-color;
  margin: -0.5 * $dropdown-border-width;
  display: inline-block;
}

.grouped-dropdown-row {
  .bordered-menu-group:first-child { border-left: 0; }
  .bordered-menu-group:last-child { border-right: 0; }

  .bordered-menu-group:not(:first-child) { border-left: 0; }
  display: flex;
  align-items: stretch;
  justify-content: center;
}

.grouped-dropdown-row:not(:first-child) .bordered-menu-group {
  border-top: 0;
}

.grouped-dropdown-row:last-child {
  .bordered-menu-group { 
    border-bottom: 0; 
    padding-bottom: 0.5rem;
    &:first-child { border-left: 0; }
    &:last-child { border-right: 0; }
  }
}
